<template>
  <div class="job-look-up">
    <div class="job-look-up__title">Job look up</div>
    <div class="job-look-up__container-search" v-if="tab === 'search'">
      <form @submit.prevent="search">
        <v-icon class="search-icon"> </v-icon>
        <v-text-field
          v-model="searchValue"
          placeholder="Job ID or Email"
          required
        ></v-text-field>
        <v-btn class="search" type="submit">Search</v-btn>
      </form>
    </div>
    <div v-else class="job-look-up__container-result">
      <div class="search-result">
        <div>Search Results for:</div>
        <div class="bold">{{ searchValue }}</div>
      </div>
      <div class="table-box">
        <v-data-table
          :disable-sort="true"
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item.result="{ item }">
            <v-btn
              v-if="item.status.length > 0"
              :class="['result-button', item.status.toLowerCase()]"
              @click="result(item)"
              >{{ item.result }}</v-btn
            >
          </template>
        </v-data-table>
      </div>
    </div>

    <div @click="back">
      <back-button :backLink="tab === 'search' ? '/' : backLink" />
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton';
import timediff from 'timediff';
const VUE_APP_URL = process.env.VUE_APP_URL || '';
import addMinutes from 'date-fns/addMinutes';
import moment from 'moment'
import { mapActions } from 'vuex';
export default {
  name: 'JobLookUp',
  components: {
    BackButton,
  },
  data() {
    return {
      backLink: 'job-look-up',
      searchValue: '',
      tab: 'search',
      headers: [
        {
          text: 'Job ID#',
          align: 'start',
          value: 'id'
        },
        { text: 'Type', value: 'type' },
        { text: 'Status', value: 'status' },
        { text: 'Submitted Date', value: 'submittedDate' },
        { text: '', value: 'result' }
      ],
      items: []
    };
  },
  computed: {
    isForbiddenSymbolAtStart() {
      return this.searchValue.indexOf('#') === 0
    },
  },
  methods: {
    ...mapActions(['setProcessType']),
    validateSearch(search) {
      const regEx = new RegExp(/^#*/g)
      const regExMatch = search.match(regEx)
      if (
        !search.length ||
        this.isForbiddenSymbolAtStart && search.length === 1 ||
        this.isForbiddenSymbolAtStart && search.includes('@') ||
        regExMatch[0]?.length > 1
      ) {
        this.$vToastify.error({
          body: `Please, type correct Job ID or Email`,
          title: ' ',
          duration: 2000,
          icon: ' ',
          type: 'error'
        });
        return false
      }
      return true
    },
    search() {
      this.tab = `result`;
      let search = this.searchValue.trim()
      if (!this.validateSearch(search)) return
      if (this.isForbiddenSymbolAtStart && search.length > 1) {
        search = search.replace(/^#*/g, '')
      }
      let query =
      search.includes('@') === true
          ? `email=${search}`
          : `job_id=${search || 0}`;

      this.axios
        .get(VUE_APP_URL + `/api/v1/jobs?${query}`)
        .then(({ data }) => {
          const items = data.map(item => {
            const submissionDatetime = new Date(item.submission_datetime);
            const currentDate = new Date();

            const timeDiff = timediff(
              addMinutes(
                submissionDatetime,
                moment().utcOffset()
              ),
              currentDate
            );

            let timeAgo = 'Unknown';
            if (timeDiff.years > 0) {
              timeAgo = Math.abs(timeDiff.years) + ' years ago';
            } else if (timeDiff.months > 0) {
              timeAgo = Math.abs(timeDiff.months) + ' months ago';
            } else if (timeDiff.weeks > 0) {
              timeAgo = Math.abs(timeDiff.weeks) + ' weeks ago';
            } else if (timeDiff.days > 0) {
              timeAgo = Math.abs(timeDiff.days) + ' days ago';
            } else if (timeDiff.hours > 0) {
              timeAgo = Math.abs(timeDiff.hours) + ' hours ago';
            } else if (timeDiff.minutes !== 0) {
              timeAgo = `${Math.abs(timeDiff.minutes)} minutes ago`;
            } else {
              timeAgo = `${Math.floor(Math.abs(timeDiff.seconds)) ||
                1} seconds ago`;
            }

            return {
              ...item,
              type: item.type[0].toUpperCase() + item.type.slice(1),
              submittedDate: timeAgo,
              result: item.status === 'Completed' ? 'Results' : 'Results'
            };
          });

          this.items = items;
        })
        .catch(error => console.log('error', error));

      this.$router.push(`/job-look-up?search=${this.searchValue}`).catch(() => {})
    },
    result(item) {
      if (item && item.status !== 'Processing') {
        const searchValue = this.searchValue.length
          ? this.searchValue
          : this.$route.params.searchValue;

        this.$router.push({
          name: 'OutputPage',
          query: { job_id: item.id, type: `result-${item.type}`.toLocaleLowerCase() },
          params: { search: searchValue || '' }
        }).catch(() => {})
      } else if (item && item.status == 'Processing') {
        this.$router.push(`/processing-page?job_id=${item.id}`).catch(() => {})
      }
    },
    back() {
      this.tab = 'search';
      this.searchValue = '';
      this.items = []
    }
  },
  created() {
    this.setProcessType('Find previous job');
    if (this.$route.query && this.$route.query.search?.length > 0) {
      this.tab = 'result';
      this.searchValue = this.$route.query.search || 0;
      this.search();
    }
  }
};
</script>

<style lang="scss" scoped>
.job-look-up {
  &__title {
    font-family: var(--headline-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    color: var(--kern-blue);
    margin-top: 4.3125rem;
    margin-bottom: 24px;
    height: 54px;
  }

  &__container {
    &-search {
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      border-radius: 10px;

      margin: 0 10vw;

      form {
        background: #ffffff;
        border: 1px solid #d4d4d4;
        box-sizing: border-box;
        border-radius: 0.375rem;
        max-width: 45.9375rem;
        height: 5rem;
        margin: 172px auto;
        display: flex;
        padding-left: 17px;

        @media (max-width: 1089px) {
          margin: 172px auto;
        }

        .search-icon {
          background-image: url('../assets/search.svg');
          height: 23px;
          width: 22.97px;
          display: block;
          float: left;
          margin-top: 30px;
          margin-right: 10px;

          @media (max-width: 568px) {
            margin-left: 18px;
            margin-top: 30px;
          }
        }

        ::v-deep .v-text-field > .v-input__control > {
          .v-input__slot:after,
          .v-input__slot:before {
            display: none;
          }
        }

        ::v-deep .v-input input {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          color: #8a8a8a;
          font-size: 22px;
          line-height: 28px;
          margin-top: 27px;
          margin-right: 10px;
        }

        .search {
          box-shadow: none;
          background: #f2545b;
          border-radius: 4px;
          color: #ffffff;
          width: 11.375rem;
          height: 4.375rem;
          font-family: Karla;
          font-style: normal;
          font-weight: bold;
          font-size: 1.375rem;
          line-height: 1.625rem;
          align-self: center;
          margin-right: 0.3125rem;
        }
      }

      .v-data-table__mobile-row {
        margin-bottom: 20px;
      }

      @media (min-width: 768px) and (max-width: 1089px) {
        padding: 0 54px;
      }

      @media (max-width: 1090px) {
        form {
          max-width: 735px;
        }
      }

      @media (max-width: 768px) {
        form {
          max-width: 540px;
        }
      }

      @media (min-width: 568px) and (max-width: 767px) {
        margin: 0 10px;
        padding: 129px 31px 130px;
        form {
          margin: 0;
        }
      }
      @media (max-width: 567px) {
        margin-top: 24px;
        form {
          margin: 36px 31px 235px !important;
          display: block !important;
          padding: 0;

          height: 80px;

          ::v-deep .v-input {
            height: 80px;
            padding-left: 18px;
            padding-top: 0;
            margin-top: 0;
          }

          .search {
            height: 70px;
            width: 100%;
            margin: 0 auto;
            display: block;
            margin-top: 15px;
          }
        }
      }

      @media (max-width: 567px) {
        margin-right: 0 !important;
        margin-left: 0 !important;
        border: none;
        form {
          padding-left: 0;
          height: 80px;
          ::v-deep .v-text-field__slot {
            height: 80px;
          }
          ::v-deep .v-input {
            height: 80px;
            padding-left: 18px;
            padding-top: 0;
            margin-top: 0;

            input {
              margin-top: 27px;
              height: 80px;

              display: flex;
              align-items: center;
            }
          }
          .search {
            margin-top: 15px;
            width: 100%;
            height: 70px;
          }
        }
      }

      @media (max-width: 767px) {
        form {
          margin-right: 10px !important;
          margin-left: 10px !important;
        }
      }
    }

    &-result {
      margin: auto 10vw;

      @media (min-width: 768px) and (max-width: 1090px) {
        margin: 10px 10vw;
      }
      @media (max-width: 767px) {
        margin-right: 10px;
        margin-left: 10px;
      }

      .search-result {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
        display: flex;
        align-items: center;

        color: var(--kern-blue);

        @media (max-width: 767px) {
          flex-direction: column;
        }

        div.bold {
          font-family: Source Sans Pro;
          font-weight: bold;
          margin-left: 7px;
        }
      }

      .table-box {
        margin-top: 46px;
        overflow: hidden;
      }

      ::v-deep .v-data-table {
        overflow: hidden;

        &__wrapper {
          overflow: hidden;
        }
        table {
          border-collapse: collapse !important;
        }

        &-header {
          border-radius: 6px;
          background: #f2f2f6;
          span {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 25px;
            display: flex;
            align-items: center;

            color: #8a8a8a;
          }
          th {
            border-bottom: none !important;
          }
        }

        tbody td {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 25px;
          align-items: center;

          color: #8a8a8a;
        }

        tbody tr {
          height: 100px;
          @media (max-width: 767px) {
            td {
              padding-left: 3px !important;
              padding-right: 5px !important;
              padding-top: 4px !important;
              padding-bottom: 4px !important;
            }
          }
          @media (min-width: 768px) {
            border-bottom: 1px solid #d4d4d4;
            &:hover {
              background: #ffffff !important;
              border: 1px solid #d4d4d4;
              border-left: 2px solid #d4d4d4;
              border-right: 2px solid #d4d4d4;
              box-sizing: border-box;
              border-radius: 6px;
              padding: 0 1px;
            }
          }

          @media (max-width: 767px) {
            height: 100% !important;
          }

          .v-btn {
            background-color: #394458;
            color: #ffffff;
            &.processing {
              background: #ffffff;
              color: #394458;
            }
          }

          @media (min-width: 600px) {
            background: #ffffff;
            border-bottom: 1px solid #d4d4d4;
          }

          @media (max-width: 599px) {
            height: 346px;
            padding-top: 32px;
            padding-bottom: 39px;

            &:nth-child(odd) {
              background: #f2f2f6;
            }

            .v-data-table__mobile-row__header {
              font-family: Source Sans Pro;
              font-style: normal;
              font-weight: normal;
              font-size: 20px;
              line-height: 25px;
              display: flex;
              align-items: center;

              color: #000000;
            }

            .v-data-table__mobile-row:last-of-type {
              margin-top: 41px;
              .v-data-table__mobile-row__header {
                display: none;
              }
            }

            .v-data-table__mobile-row__cell {
              width: 40%;
              text-align: left;
              .v-btn {
                cursor: pointer;
                width: 140px;
                height: 50px;

                background: var(--kern-blue);
                border-radius: 6px;

                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;

                display: flex;
                align-items: center;
                text-transform: uppercase;

                color: #ffffff;

                &.processing {
                  background: #ffffff;
                  color: #394458;
                }
              }
            }
          }
          @media (max-width: 767px) {
            .v-data-table__mobile-row:last-of-type {
              margin-top: 30px;
            }
          }
          td {
            border-bottom: none !important;

            @media (max-width: 767px) {
              padding-left: 21px;
            }
          }
          &:hover {
            cursor: pointer;
            td {
              color: var(--kern-blue);
            }
          }
        }
      }
    }
  }

  ::v-deep .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
